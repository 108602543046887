import React, {useEffect, useRef} from 'react';
import '../styles.scss';
import PropTypes from 'prop-types';
import Screen from '../components/Screen';

/** @jsxImportSource @emotion/react */

export default function ScoresScreen(
	{
		autoScroll = true,
		scrollDelay = 5,
		changeDirectionDelay,
		styles = {
			title: {},
			page: {}
		},
		showPos = false,
		showTeam = false,
		showNewScores = true,
		title,
		scores
	}
) {
	const ref = useRef(null);
	
	useEffect(() => {
		let timeout;
		let active = true;
		
		if (!autoScroll) {
			const el = ref && ref.current;
			if (el) {
				el.scrollTop = 0;
			}
			
		} else {
			let direction = 'down';
			const step = 1;
			const delay = scrollDelay * 1000;
			const cdDelay = typeof changeDirectionDelay === 'undefined' ? delay : changeDirectionDelay * 1000;
			
			const scroll = () => {
				if (!active) {
					return;
				}
				
				const el = ref && ref.current;
				if (el) {
					const currentScroll = Math.ceil(el.scrollTop);
					if (direction === 'down') {
						el.scrollTop = currentScroll + step;
						if (el.scrollHeight - el.scrollTop <= el.clientHeight) {
							direction = 'up';
							return setTimeout(scroll, cdDelay);
						}
					} else {
						el.scrollTop = currentScroll - step;
						if (el.scrollTop <= 0) {
							direction = 'down';
							return setTimeout(scroll, cdDelay);
						}
					}
				}
				window.requestAnimationFrame(scroll);
			}
			
			timeout = setTimeout(scroll, delay);
		}
		
		return () => {
			clearTimeout(timeout);
			active = false;
		}
	}, [autoScroll, scrollDelay, changeDirectionDelay]);
	
	// Calculate column sizes.
	let maxScore = 0;
	let maxTotalScore = 0;
	scores.forEach(row => {
		maxScore = Math.max(maxScore, Math.abs(row.scores));
		maxTotalScore = Math.max(maxTotalScore, Math.abs(row.totalScores));
	});
	
	return <Screen
		className={`scores has-scores`}
		bgColor={styles.page.bgColor}
		mediaBg={styles.page.background}
		css={{
			'.screen-title': {
				fontSize: `${styles.title.fontSize}px`,
				color: styles.title.color,
				backgroundColor: styles.title.bgColor
			},
			
			'.row': {
				fontSize: `${styles.page.fontSize}px`,
				backgroundColor: styles.page.rowBgColor,
				'& .position': {
					color: styles.page.textColor,
					width: `${scores.length.toString().length}ch`
				},
				'& .user-name': {
					color: styles.page.textColor
				},
				'& .user-team': {
					color: styles.page.textColor
				},
				'& .scores': {
					color: styles.page.scoreColor,
					width: `${maxScore.toString().length + 1}ch`
				},
				'& .scores.penalty': {
					color: styles.page.penaltyScoreColor
				},
				'& .total-scores': {
					color: styles.page.totalScoresColor,
					width: `${maxTotalScore.toString().length}ch`
				}
			},
			'& .user-name': {
				color: styles.page.textColor
			}
		}}
	>
		<div className={`screen-title`}>{title}</div>
		<div className={`screen-content`}>
			{scores.length > 0 && (
				<div className={`rows`} ref={ref}>
					{scores.map((s, i) => (
						<div key={`si${s.user.channelId}`} className={`row`}>
							{showPos
								? <div className={'position'}>{showPos ? (i + 1) : ''}</div>
								: <div></div>
							}
							<div className={'user-img'}><img src={s.user.profileImageUrl} alt={'profile pic'}/></div>
							<div className={`user-name`}>{s.user.displayName}</div>
							
							{(showTeam && s.team)
								? <div className={'user-team'} style={{
									textShadow: `
								0 0 0px ${s.team.color},
								0 0 5px ${s.team.color},
								0 0 10px ${s.team.color},
								0 0 15px ${s.team.color},
								0 0 20px ${s.team.color},
								0 0 25px ${s.team.color}`
								}}>{s.team.name}</div>
								: <div></div>
							}
							
							{showNewScores
								? <div className={`scores ${s.scores < 0 ? 'penalty' : ''}`}>{s.scores > 0 ? '+' : ''}{s.scores}</div>
								: <div></div>
							}
							
							<div className={'total-scores'}>{s.totalScores}</div>
						</div>
					))}
				</div>
			)}
		</div>
	</Screen>
}

ScoresScreen.propTypes = {
	autoScroll: PropTypes.bool,
	changeDirectionDelay: PropTypes.number,
	scores: PropTypes.array.isRequired,
	scrollDelay: PropTypes.number,
	showNewScores: PropTypes.bool,
	showPos: PropTypes.bool,
	showTeam: PropTypes.bool,
	styles: PropTypes.object,
	title: PropTypes.string.isRequired,
};
